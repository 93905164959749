<template>  
      <v-img
        class="ma-3"
        :src="
          require('@/assets/wawee-logo-neg-web.png')"
      />  
</template>

<script>
  export default {
    name: 'DefaultDrawerHeader',
  }
</script>
